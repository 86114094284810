<template>
  <v-card flat>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="isApprovePegawai"
        color="warning"
        :loading="cancelLoading"
        @click="handleChange('cancel')"
        >HAPUS</v-btn
      >
      <v-btn
        v-if="isApprovePegawai"
        color="success"
        :loading="approveLoading"
        @click="handleChange('approve')"
        >SETUJUI</v-btn
      >
      <v-btn
        v-if="isDeclinePegawai"
        class="ml-2"
        color="error"
        :loading="declineLoading"
        @click="handleChange('decline')"
        >TOLAK</v-btn
      >
    </v-card-actions>
    <v-row class="mt-4 ml-4" id="input-custom">
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <p class="ma-1 subtitle-2 font-weight-regular headline-color">
          Nama Pegawai
        </p>
        <p class="ma-1 subtitle-2 font-weight-bold headline-color">
          <v-text-field
            v-if="isUpdate"
            outlined
            dense
            hide-details
            v-model="personal.nama"
          ></v-text-field>
          <span v-else>{{ personal.nama }}</span>
        </p>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <p class="ma-1 subtitle-2 font-weight-regular headline-color">
          NIP
        </p>
        <p class="ma-1 subtitle-2 font-weight-bold headline-color">
          <v-text-field
            v-if="isUpdate"
            outlined
            dense
            hide-details
            v-model="personal.NIP"
          ></v-text-field>
          <span v-else>{{ personal.NIP }}</span>
        </p>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
        <p class="ma-1 subtitle-2 font-weight-regular headline-color">
          Nomor ID Presensi
        </p>
        <p class="ma-1 subtitle-2 font-weight-bold headline-color">
          <v-text-field
            v-if="isUpdate"
            outlined
            dense
            hide-details
            v-model="personal.presensi_id"
          ></v-text-field>
          <span v-else>{{ personal.presensi_id }}</span>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs show-arrows height="50" v-model="tab" id="tab-data">
          <v-tab
            v-for="(item, i) in tabs"
            v-bind:key="i"
            class="mr-2 px-5 text-upppercase"
            >{{ item.text }}</v-tab
          >
        </v-tabs>
        <v-tabs-items>
          <transition name="slide-fade" mode="out-in">
            <keep-alive>
              <component
                :is="tabs[tab].component"
                :isUpdate="isUpdate"
                :isApprove="true"
              />
            </keep-alive>
          </transition>
          <v-card-actions v-if="isUpdate && tab < 4">
            <v-spacer />
            <v-btn
              small
              :loading="formLoading"
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              @click="handleSubmit"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapEmployeeRootField } from "@/store/helpers";
import PegawaiService from "@/services/resources/pegawai.service";
import { mapGetters } from "vuex";
import { ACTION_ACCESS } from "@/common/constant";

// Tab
const DataPribadi = () => import("@/views/DataPegawai/Tab/DataPribadi");
const DataKepegawaian = () => import("@/views/DataPegawai/Tab/DataKepegawaian");
const DataKeluarga = () => import("@/views/DataPegawai/Tab/DataKeluarga");

export default {
  components: {
    DataPribadi,
    DataKepegawaian,
    DataKeluarga
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.historyId : null,
      NIP: this.$route.query ? this.$route.query.NIP : null,
      tbsource: this.$route.query ? this.$route.query.tbsource : null,
      isUpdate: false,
      formLoading: false,
      cancelLoading: false,
      approveLoading: false,
      declineLoading: false,
      tab: 0,
      tabs: [
        {
          id: "data-pribadi",
          text: `Data Pribadi`,
          icon: "",
          component: "DataPribadi"
        },
        {
          id: "data-kepegawaian",
          text: `Data Kepegawaian`,
          icon: "",
          component: "DataKepegawaian"
        }
      ]
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal",
      employee: "employee",
      keluarga: "keluarga",
      unit_kerja: "unit_kerja"
    }),
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    isApprovePegawai() {
      return ACTION_ACCESS.PERUBAHAN_DATA_PEGAWAI.APPROVE.find(
        d => d == this.currentUser.role
      );
    },
    isDeclinePegawai() {
      return ACTION_ACCESS.PERUBAHAN_DATA_PEGAWAI.DECLINE.find(
        d => d == this.currentUser.role
      );
    }
  },
  watch: {
    isUpdate(val) {
      if (!val) {
        this.$emit("on-refresh");
      }
    }
  },
  methods: {
    handleChange(type = "approve") {
      this.$confirm({
        title: "Konfirmasi",
        message: `Apakah anda yakin akan ${
          type == "approve"
            ? "menyetujui"
            : type == "decline"
            ? "menolak"
            : "menghapus"
        } perubahan ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              history_id: this.id,
              NIP: this.personal.NIP,
              isApproved: type == "approve" ? 1 : type == "decline" ? 2 : 0,
              tbsource: this.tbsource
            };
            this.handleApprove(payload, type);
          }
        }
      });
    },
    // Service
    async getDetailEmployee() {
      try {
        this.loadingUnit = true;
        await PegawaiService.getDetailHistory({
          history_id: this.id,
          NIP: this.NIP,
          tbsource: this.tbsource
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.$store.commit("employee/resetEmployeeState");
              data.employee.cpns.tgl_mulai_CPNS = data.employee.cpns
                .tgl_mulai_CPNS
                ? this.$moment(data.employee.cpns.tgl_mulai_CPNS).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.cpns.tgl_sk_CPNS = data.employee.cpns.tgl_sk_CPNS
                ? this.$moment(data.employee.cpns.tgl_sk_CPNS).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.golongan_pangkat.tmt_gol = data.employee
                .golongan_pangkat.tmt_gol
                ? this.$moment(data.employee.golongan_pangkat.tmt_gol).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.golongan_pangkat.tgl_sk_gol = data.employee
                .golongan_pangkat.tgl_sk_gol
                ? this.$moment(
                    data.employee.golongan_pangkat.tgl_sk_gol
                  ).format("YYYY-MM-DD")
                : null;
              data.employee.jabatan_eselon.tgl_sk_jabatan = data.employee
                .jabatan_eselon.tgl_sk_jabatan
                ? this.$moment(
                    data.employee.jabatan_eselon.tgl_sk_jabatan
                  ).format("YYYY-MM-DD")
                : null;
              data.employee.jabatan_eselon.tmt_jabatan = data.employee
                .jabatan_eselon.tmt_jabatan
                ? this.$moment(data.employee.jabatan_eselon.tmt_jabatan).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.pns.tgl_mulai_PNS = data.employee.pns.tgl_mulai_PNS
                ? this.$moment(data.employee.pns.tgl_mulai_PNS).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.employee.pns.tgl_sk_PNS = data.employee.pns.tgl_sk_PNS
                ? this.$moment(data.employee.pns.tgl_sk_PNS).format(
                    "YYYY-MM-DD"
                  )
                : null;
              data.personal.tanggal_lahir = data.personal.tanggal_lahir
                ? this.$moment(data.personal.tanggal_lahir).format("YYYY-MM-DD")
                : null;
              data.personal.rt = data.personal.rt_rw.split("/")[0];
              data.personal.rw = data.personal.rt_rw.split("/")[1];

              data.personal.kelurahan = data.personal.kelurahan_data;
              data.personal.kecamatan = data.personal.kecamatan_data;
              data.personal.kabupaten = data.personal.kabupaten_data;
              data.personal.provinsi = data.personal.provinsi_data;
              data.personal.pendidikan.jenjang =
                data.personal.pendidikan.jenjang_data;
              data.personal.pendidikan.bidang_studi =
                data.personal.pendidikan.bidang_studi_data;
              data.employee.jabatan_eselon.jabatan =
                data.employee.jabatan_eselon.jabatan_data;
              data.employee.jabatan_eselon.eselon =
                data.employee.jabatan_eselon.eselon_data;
              data.employee.golongan_pangkat.gol =
                data.employee.golongan_pangkat.gol_data;
              data.employee.golongan_pangkat.pangkat =
                data.employee.golongan_pangkat.pangkat_data;

              data.personal.tanggal_lahir = data.personal.tgl_lahir
                ? this.$moment(data.personal.tgl_lahir).format("YYYY-MM-DD")
                : null;

              this.personal = this.$_.merge(this.personal, data.personal);
              this.employee = this.$_.merge(this.employee, data.employee);
              this.unit_kerja = this.$_.merge(this.unit_kerja, data.unit_kerja);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async handleApprove(data, type = "approve") {
      try {
        this[type + "Loading"] = true;
        await PegawaiService.approve(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$router.back();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[type + "Loading"] = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getDetailEmployee();
  }
};
</script>
<style lang="scss">
#tab-data .v-tab {
  font-size: 0.675rem;
}
#input-custom .v-input {
  font-size: 0.675rem;
}
</style>
